.pos-absolute {
	position: absolute;
}

.loading {
	position: relative;
	width: 100px;
	height: 100px;
}
.circle {
	position: absolute;
	aspect-ratio: 1 / 1;
	border-radius: 50%;
	background-color: #fff;
}

.loading-string {
	text-align: center;
	font-weight: bold;
}

.loading-string p {
	font-weight: bold;
}

.loading .progress-bar {
	width: 100%;
	position: absolute;
	bottom: -10%;
	left: 50%;
	transform: translateX(-50%);
}

.circle-a {
	/* background-color: red; */
	min-width: 15px;
	right: 25%;
	top: 60%;
	z-index: 3;

	animation: circle-a-anime 9s linear infinite alternate-reverse;
	animation-fill-mode: forwards;
}
.circle-b {
	/* background-color: #5205c8; */
	z-index: 2;
	min-width: 60px;
	animation: circle-b-anime 9s linear infinite alternate-reverse;
	animation-fill-mode: forwards;
	opacity: 0.8;
	top: 10%;
	left: 0%;
}

.circle-c {
	/* background-color: #8801fe; */
	min-width: 35px;
	animation: circle-c-anime 9s linear infinite alternate-reverse;
	animation-fill-mode: forwards;
	opacity: 0.7;
	top: 20%;
	right: 5%;
}

@keyframes circle-a-anime {
	0% {
		transform: scale(1);
		right: 25%;
		top: 60%;
	}
	30% {
		transform: scale(2);
		top: 50%;
		right: 30%;
	}
	50% {
		transform: scale(0.6);
		top: 60%;
		right: 30%;
	}
	70% {
		transform: scale(1.5);
		right: 50%;
	}
	90% {
		transform: scale(1.7);
		right: 30%;
	}
	100% {
		transform: scale(0.6);
		right: 50%;
		top: 70%;
	}
}

@keyframes circle-b-anime {
	0% {
		transform: scale(1);
		top: 10%;
		left: 0%;
	}
	30% {
		transform: scale(0.3);
		top: 0%;
		left: 60%;
	}
	60% {
		transform: scale(1);
		top: 40%;
		left: 0%;
	}
	80% {
		transform: scale(0.3);
		top: 0%;
		left: 0%;
	}
	100% {
		transform: scale(0.3);
		left: 50%;
		top: 50%;
	}
}

@keyframes circle-c-anime {
	0% {
		transform: scale(1);
		top: 20%;
		right: 5%;
	}
	30% {
		transform: scale(2);
		top: 30%;
		right: 60%;
	}
	50% {
		transform: scale(0.6);
		top: 40%;
		right: 5%;
	}
	70% {
		transform: scale(1.5);
		top: 40%;
		right: 20%;
	}
	90% {
		transform: scale(1.5);
		top: 40%;
		right: 50%;
	}
	100% {
		transform: scale(1);
		right: 5%;
		top: 20%;
	}
}
