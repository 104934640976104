@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

body.dark {
	background: radial-gradient(111.11% 111.11% at 50% 41.11%, #403748 21.88%, #07010c 100%);
	color: #f8f1f1;
}

body.light {
	background: #fafafa;
	color: #000;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
	min-height: 100vh;
}

.reloadAlbums {
	display: inline-block;
	vertical-align: bottom;
	padding-left: 10px;
}
.reloadAlbums img {
	width: 32px;
}
.parent_folder_container button {
	display: inline-block;
	border: none;
	background: none;
	color: #f00;
	text-transform: capitalize;
}
.parent_folder_container > div {
	display: inline-block;
}
.parent_folder_container button:last-child span {
	display: none;
}
.parent_folder_container button span {
	padding-left: 10px;
}
.parent_folder_container > div button:last-child span {
	display: inline-block;
}
button.back_sub_folder {
	background: #000;
	display: inline-block;
	color: #fff;
	margin: 0 10px 10px 0;
	padding: 7px 15px;
}
.gap_20 {
	margin-top: 20px;
}
button.home_sub_folder span.MuiIconButton-label {
	padding: unset;
}
button.home_sub_folder {
	text-align: center;
	color: #000;
}
.button_rc {
	display: inline-block;
}
.delete_rc.MuiChip-deleteIcon {
	background: #00000042;
	border-radius: 50%;
	height: 16px;
	width: 16px;
}
/* .uploadPic .MuiGrid-grid-lg-6 .MuiPaper-rounded,
.uploadPic .MuiGrid-grid-lg-3 .MuiPaper-rounded{
  min-height: 635px;
} */
.updatePicMin .MuiPaper-rounded,
.updatePicLft .MuiPaper-rounded {
	min-height: 475px;
}
.uploadPic .MuiGrid-grid-lg-3 .div-img-wrapper {
	display: inline-flex;
	justify-content: center;
	align-items: flex-end;
	min-height: 443px;
}
.uploadPic .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-6 {
	justify-content: center;
	align-items: flex-end;
}
.uploadPic .MuiGrid-grid-lg-3 .div-img-wrapper img {
	width: 100% !important;
	height: auto !important;
}
.uploadPic .MuiGrid-grid-lg-6 img {
	width: 100% !important;
	height: auto !important;
}
.uploadPic header .MuiToolbar-gutters button.MuiButtonBase-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart {
	padding: 5px !important;
}
.uploadPic button.MuiButton-contained.MuiButton-containedPrimary {
	margin-bottom: 4px;
	border-radius: 0px !important;
}
.partSeg h3 {
	margin: 0px;
	font-weight: 600;
	font-size: 15px;
	border-bottom: 2px solid #efefef;
	padding-bottom: 5px;
	text-align: center;
	margin-bottom: 5px;
}
.partSegUpr,
.faceSegUpr {
	display: flex;
	align-items: center;
}
.partSegUprLft,
.partSegUprRght,
.faceSegUprLft,
.faceSegUprRght {
	width: 50%;
}
.partSegUprRght,
.faceSegUprRght {
	text-align: right;
}
.partSegUprRght .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track,
.faceSegUprRght .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
	background-color: #015401;
}
.partSegUprRght .MuiSwitch-colorSecondary.Mui-checked,
.faceSegUprRght .MuiSwitch-colorSecondary.Mui-checked {
	color: #fff !important;
}
.faceRecog h4 {
	margin: 0px;
	font-weight: 600;
	font-size: 15px;
	margin-top: 12px;
	border-bottom: 2px solid #efefef;
	padding-bottom: 5px;
	text-align: center;
	margin-bottom: 5px;
}
.checkHghtWght label {
	margin-right: 0px !important;
	font-size: 14px !important;
	margin-bottom: 6px;
	margin-left: 0px !important;
}
.checkHghtWght label span {
	font-size: 15px !important;
	padding: 0px !important;
}
.faceRecog {
	margin-bottom: 4px;
}
.checkHghtWght .MuiCheckbox-colorSecondary.Mui-checked {
	color: #445eec !important;
}
.partSegUprRght .MuiSwitch-root,
.faceSegUprRght .MuiSwitch-root {
	padding: 6px !important;
}
.partSegUprRght .MuiSwitch-track,
.faceSegUprRght .MuiSwitch-track {
	border-radius: 12px !important;
}
.reactEasyCrop_Container {
	z-index: 999 !important;
}
.container-img {
	position: relative;
}

.image-org {
	display: block;
	width: 100%;
	height: auto;
	top: 0;
	left: 0;
}

.image-mask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transition: 0.5s ease;
	opacity: 0;
}

.image-mask {
	opacity: 1;
}

.div-img-wrapper {
	cursor: pointer;
}

.div-loader {
	display: flex;
	justify-content: center;
	position: absolute;
	background: rgba(255, 255, 255, 0.8);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0px;
	z-index: 99;
	align-items: center;
	overflow: hidden;
	margin: 0 auto;
}
.fill {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.fill img {
	flex-shrink: 0;
	min-width: 100%;
	min-height: 100%;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.Resizer {
	background: transparent;
	opacity: 0.2;
	z-index: 1;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

.Resizer:hover {
	transition: all 2s ease;
}

.Resizer.horizontal {
	height: 2px;
	width: 90%;
	margin: 0px auto 5px auto;
	border-top: 1px solid white;
	border-bottom: 0px solid transparent;
	cursor: row-resize;
	box-shadow: 0px 4px 9px 1px rgb(0 0 0 / 60%);
}

.Resizer.vertical {
	width: 11px;
	/* margin: 15% -5px; */
	border-left: 5px solid rgba(255, 255, 255, 0);
	border-right: 5px solid rgba(255, 255, 255, 0);
	cursor: col-resize;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
	display: flex;
	justify-content: flex-start;
}

.slick-initialized .slick-slide {
	margin-top: auto;
}

.Toastify__toast--dark {
	padding: 12px;
	border-radius: 10px;
	min-width: 800px;
}

.Toastify__toast-container {
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
}

.MuiPaper-root {
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
	border-radius: 2px !important;
}

.MuiList-root {
	padding: 0 !important;
}
::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	border-radius: 5px;
	height: 4px;
}

::-webkit-scrollbar-thumb {
	border-radius: 24px;
	background: rgba(91, 91, 91, 0.5);
	max-height: 156px;
}

::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(91, 91, 91, 0.5);
}

.scroll-box {
	overflow-y: scroll;
}
::-webkit-scrollbar {
	width: 4.02px;
	max-height: 156px;
}

.searchCharactres::-webkit-scrollbar {
	display: none; /* for Chrome, Safari and Opera */
}

@font-face {
	font-family: "Gotham Narrow Book";
	src: url(https://int.bria.ai/static/media/GothamNarrow-Book.d2c3767c.woff);
}

@font-face {
	font-family: "Gotham Narrow Bold";
	src: url(https://int.bria.ai/static/media/GothamNarrow-Bold.89cdfe61.woff);
}

@font-face {
	font-family: "Gotham Narrow Black";
	src: url(https://int.bria.ai/static/media/GothamNarrow-Black.17537201.woff);
}

@font-face {
	font-family: "Gotham Narrow Medium";
	src: url(https://int.bria.ai/static/media/GothamNarrow-Medium.bdc2a99f.woff);
}

@font-face {
	font-family: "Helvetica Neue Regular";
	src: url(https://int.bria.ai/static/media/HelveticaNeue-Regular.c8b1f94d.woff);
}

@font-face {
	font-family: "Helvetica Neue Bold";
	src: url(https://int.bria.ai/static/media/HelveticaNeue-Bold.631c2b80.woff);
}

.pos-absolute {
	position: absolute;
}

.loading {
	position: relative;
	width: 100px;
	height: 100px;
}
.circle {
	position: absolute;
	aspect-ratio: 1 / 1;
	border-radius: 50%;
	background-color: #fff;
}

.loading-string {
	text-align: center;
	font-weight: bold;
}

.loading-string p {
	font-weight: bold;
}

.loading .progress-bar {
	width: 100%;
	position: absolute;
	bottom: -10%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.circle-a {
	/* background-color: red; */
	min-width: 15px;
	right: 25%;
	top: 60%;
	z-index: 3;

	animation: circle-a-anime 9s linear infinite alternate-reverse;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}
.circle-b {
	/* background-color: #5205c8; */
	z-index: 2;
	min-width: 60px;
	animation: circle-b-anime 9s linear infinite alternate-reverse;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	opacity: 0.8;
	top: 10%;
	left: 0%;
}

.circle-c {
	/* background-color: #8801fe; */
	min-width: 35px;
	animation: circle-c-anime 9s linear infinite alternate-reverse;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	opacity: 0.7;
	top: 20%;
	right: 5%;
}

@-webkit-keyframes circle-a-anime {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		right: 25%;
		top: 60%;
	}
	30% {
		-webkit-transform: scale(2);
		        transform: scale(2);
		top: 50%;
		right: 30%;
	}
	50% {
		-webkit-transform: scale(0.6);
		        transform: scale(0.6);
		top: 60%;
		right: 30%;
	}
	70% {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
		right: 50%;
	}
	90% {
		-webkit-transform: scale(1.7);
		        transform: scale(1.7);
		right: 30%;
	}
	100% {
		-webkit-transform: scale(0.6);
		        transform: scale(0.6);
		right: 50%;
		top: 70%;
	}
}

@keyframes circle-a-anime {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		right: 25%;
		top: 60%;
	}
	30% {
		-webkit-transform: scale(2);
		        transform: scale(2);
		top: 50%;
		right: 30%;
	}
	50% {
		-webkit-transform: scale(0.6);
		        transform: scale(0.6);
		top: 60%;
		right: 30%;
	}
	70% {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
		right: 50%;
	}
	90% {
		-webkit-transform: scale(1.7);
		        transform: scale(1.7);
		right: 30%;
	}
	100% {
		-webkit-transform: scale(0.6);
		        transform: scale(0.6);
		right: 50%;
		top: 70%;
	}
}

@-webkit-keyframes circle-b-anime {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		top: 10%;
		left: 0%;
	}
	30% {
		-webkit-transform: scale(0.3);
		        transform: scale(0.3);
		top: 0%;
		left: 60%;
	}
	60% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		top: 40%;
		left: 0%;
	}
	80% {
		-webkit-transform: scale(0.3);
		        transform: scale(0.3);
		top: 0%;
		left: 0%;
	}
	100% {
		-webkit-transform: scale(0.3);
		        transform: scale(0.3);
		left: 50%;
		top: 50%;
	}
}

@keyframes circle-b-anime {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		top: 10%;
		left: 0%;
	}
	30% {
		-webkit-transform: scale(0.3);
		        transform: scale(0.3);
		top: 0%;
		left: 60%;
	}
	60% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		top: 40%;
		left: 0%;
	}
	80% {
		-webkit-transform: scale(0.3);
		        transform: scale(0.3);
		top: 0%;
		left: 0%;
	}
	100% {
		-webkit-transform: scale(0.3);
		        transform: scale(0.3);
		left: 50%;
		top: 50%;
	}
}

@-webkit-keyframes circle-c-anime {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		top: 20%;
		right: 5%;
	}
	30% {
		-webkit-transform: scale(2);
		        transform: scale(2);
		top: 30%;
		right: 60%;
	}
	50% {
		-webkit-transform: scale(0.6);
		        transform: scale(0.6);
		top: 40%;
		right: 5%;
	}
	70% {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
		top: 40%;
		right: 20%;
	}
	90% {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
		top: 40%;
		right: 50%;
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		right: 5%;
		top: 20%;
	}
}

@keyframes circle-c-anime {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		top: 20%;
		right: 5%;
	}
	30% {
		-webkit-transform: scale(2);
		        transform: scale(2);
		top: 30%;
		right: 60%;
	}
	50% {
		-webkit-transform: scale(0.6);
		        transform: scale(0.6);
		top: 40%;
		right: 5%;
	}
	70% {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
		top: 40%;
		right: 20%;
	}
	90% {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
		top: 40%;
		right: 50%;
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		right: 5%;
		top: 20%;
	}
}

